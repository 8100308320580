import React from 'react';
import Select from 'react-select';

import ReactCountryFlag from 'react-country-flag';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import languagesData from '../../data/languages';

export function Languages() {

  const { i18n } = useTranslation();

  const handleLanguageChange = async (selectedOption) => {
    // Check if selectedOption is defined before accessing its value
    if (selectedOption && selectedOption.value) {
      i18n.changeLanguage(selectedOption.value);
    }
  };

  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     background: "transparent",
  //     border: 0,
  //     boxShadow: 'none',
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? 'bg-blue-500' : 'bg-white', // Tailwind background color class
  //     color: state.isSelected ? 'text-white' : 'text-black', // Tailwind text color class
  //   }),
  // };
  
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: 0,
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'bg-blue-500' : 'bg-white',
      color: state.isSelected ? 'text-white' : 'text-black',
      // padding: '0.5rem 1rem', // Adjust the padding as needed
      padding: 0, 
      // paddingLeft: '0.5rem',
      margin: 0, 
      // marginLeft: '0.5rem',
      // textAlign: 'center', // Center the content horizontally
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center', // Center the content vertically
    
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'bg-secondary-light', 
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: 'bg-secondary-light', 
      width: '200px',
    }),
  };
  
  
  

  // Create options array for react-select
  const options = languagesData.map((item) => ({
    value: item.value,
    label: (
      <div 
        className="bg-secondary-light dark:bg-primary-dark text-primary-dark dark:text-ternary-light" 
        // style={{background: "transparent"}}
      >
        <ReactCountryFlag
          countryCode={item.country}
          svg
          style={{
            width: '1rem',
            height: '1rem',
            marginRight: '1rem',
            // backgroundColor: "inherit"
          }}
          title={item.label}
        />
        {item.label}
      </div>
    ),
  }));

  // Determine the default value
  const defaultValue = options.find(
    (option) => option.value === i18next.use(LanguageDetector).language
  );

  return (
    <div
        className="text-left text-lg sm:mx-4 mb-2 sm:py-2"
    >
      <Select
        // classNamePrefix="react-select bg-secondary-light dark:bg-primary-dark text-primary-dark dark:text-ternary-light"
        styles={customStyles}
        options={options}
        onChange={handleLanguageChange}
        value={defaultValue}
      />

    </div>
  );
}

export default Languages;

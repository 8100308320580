import React, { useState } from 'react';
import ChatIcon from './ChatIcon';
import Chat from './Chat';

function ChatHandler() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleChat() {
    setIsOpen(!isOpen);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <div className="App">
      {!isOpen && <ChatIcon onClick={toggleChat} />}
      {/* <ChatIcon onClick={toggleChat} /> */}
      <Chat isOpen={isOpen} onClose={handleClose} />
    </div>
  );
}

export default ChatHandler;

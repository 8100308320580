import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiMinus } from 'react-icons/fi';
import { MdRestartAlt } from "react-icons/md";

import RobotIcon from '../../images/Robot-Chat.png'
import Avatar from '../../images/avatar.png'

const env = 'P';

let ChatAPI = 'https://www.webpetersen.com:3444/send-chat'; // URL of the chat server endpoint

if (env === 'D') { ChatAPI = 'http://localhost:3444/send-chat'; }


const Chat = ({ isOpen, onClose }) => {

  const [reply, setReply] = useState([{index: "", user: "", answer: "", question: "", options: [], values: []}])
  const [messageInput, setMessageInput] = useState('');

  const [messages, setMessages] = useState({index: "1.00", value: "", message: ""});

  useEffect(() => {
     fetch(ChatAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ messages }),
    })
      .then(response => response.json())

      .then(data => { 
        setReply([...reply, data]);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  const handleSubmit = e => {
      e.preventDefault();
      setMessages({index: 0, value: 0, message: messageInput});
      fetch(ChatAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages }),
      })
      .then((r) => {console.log("handleSubmit", r)})
      setMessageInput('');
  };


   if (!isOpen) {
    return null;
  }

  function RestartChat () {

    setMessages({index: "1.00", value: "", message: ""});
    setReply([{index: "", user: "", answer: "", question: "", options: [], values: []}]);

  }

  function ChatLine (params) {

        const item = params.item;
        const key = params.index;

        useEffect(() => {
          const chatContainer = document.querySelector('.chatMessages');
          if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
          }
        }, [item]);

        return (

          <div key={key} className='chatRobotRow' id={key}>

              {item.answer.length > 0 || item.question.length > 0? <img src={RobotIcon} alt="Robot" className='w-7 mr-2'></img> : <span></span>}
      
              <div className='w-full px-4'>
                  
                  <h2>{item.answer}</h2>  
                  {item.question.length > 0 ? <h2 dangerouslySetInnerHTML={{ __html: item.question }}></h2> : <br></br>}
                  
                  {item.user.length > 0 && 
                    <div className='flex items-center justify-end '>
                      <h2 className='text-right chatUser'>{item.user}</h2> 
                      <img src={Avatar} alt="Avatar" className='w-7 mr-2'></img>  
                    </div>
                  }

                  <div className='flex flex-wrap'> 
                          
                          {item.options.length > 0 &&
                              
                              item.options.map((option, index) => { 
                                return (

                                      <button
                                      
                                        key={index} 
                                        value={item.values[index]}
                                        className='chatOptions'
                                        onClick={async (e) => {
                                          
                                          
                                          item.options = [];
                                          item.values = [];
                                          item.user = option;
                                          setMessages({index: e.target.value, value: e.target.value, message: option});

                                          }
                                        }
                                      >
                                        {option} 
                                      </button>

                                )
                              })
                          }
                  </div>
              </div>

          </div>

        )
    }


  return (

     <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="font-general-medium fixed inset-0 z-30 transition-all duration-500"
		>
			{/* Modal Backdrop */}
			<div className="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>

			{/* Modal Content */}
			<main className="flex flex-col items-center justify-center h-full w-full">
				<div className="modal-wrapper flex items-center z-30 chatForm">
					<div className="modal max-w-25 mx-5 xl:max-w-xl lg:max-w-md md:.max-w-md bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative">
						<div className="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark">
              <img src={RobotIcon} alt="Robot" className='w-10'/>
							<h5 className=" text-primary-dark dark:text-primary-light text-xl">
								Chat Assistant
							</h5>
              <button
								onClick={RestartChat}
								className="px-4 font-bold text-primary-dark dark:text-primary-light"
							>
								<MdRestartAlt className="text-3xl" />
							</button>
							<button
								onClick={onClose}
								className="px-4 font-bold text-primary-dark dark:text-primary-light"
							>
								<FiMinus className="text-3xl" />
							</button>
						</div>
						<div className="modal-body p-5 w-full h-full">

              <div className='bg-steelblue p-2 shadow-md'>

                <div className='chatMessages'>
                    {reply.map((item, index) => (
                      <div key={index}>
                        <ChatLine item={item} index={index} />
                      </div>
                    ))}
                </div>

                <form onSubmit={handleSubmit} className='flex mt-2 hidden'>
                  <input
                    type='text'
                    value={messageInput}
                    onChange={e => setMessageInput(e.target.value)}
                    className='flex-1 border-gray-400 border-2 rounded-lg py-1 px-2 mr-2 w-full'
                  />
                  <button
                    type='submit'
                    className='bg-blue-600 text-white px-4 py-1 rounded-lg'
                  >
                    Send
                  </button>
                </form>
              </div>
            
            </div>
					</div>
				</div>
			</main>
		</motion.div>
    
  );
};

export default Chat;

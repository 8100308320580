import React from 'react';
import ImageChat from '../../images/Chat-Icon.png';

const ChatIcon = ({ onClick }) => {
  return (
    <img src={ImageChat} alt='Chat' className='chatIcon' onClick={onClick}/>
  );
};

export default ChatIcon;

// import axios from 'axios';
// import { Buffer } from 'buffer';

// global.Buffer = global.Buffer || Buffer;

// import nodemailer from 'nodemailer';
//import { useState } from 'react';
import { WelcomeEmail, VisitorEmail } from '../../context/EmailContext';

// import dotenv from 'dotenv';
// dotenv.config();

export default async function sendEmail (fullName, eMail, subject, content) {

  //console.log(fullName, eMail, subject, content, process.env.REACT_EMAIL_API_URL);

  const env = 'P';
  let emailAPI = 'https://www.webpetersen.com:3443/send-email';

  const smtUser = 'admin@webpetersen.com';

  if (env === 'D') { emailAPI = 'http://localhost:3443/send-email'; }

  //console.log('WelcomeEmail(fullName)', WelcomeEmail(fullName))
  //console.log('VisitorEmail(eMail, fullName, content)', VisitorEmail(eMail, fullName, content))


  fetch(emailAPI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: fullName,
      email: eMail,
      subject: 'Thank you for visiting my website!',
      message: WelcomeEmail(fullName, subject)
    })
  })
    .then(response => {
      console.log('Email sent:', response);
    })
    .catch(error => {
      console.error('Error sending email:', error);
    });




    fetch(emailAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: fullName,
        email: smtUser,
        subject: subject,
        message: VisitorEmail(eMail, fullName, content)
      })
    })
      .then(response => {
        console.log('Email sent:', response);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });

  // const transporter = nodemailer.createTransport({
  //   host: 'smtp.titan.email',
  //   port: 587,
  //   secure: false,
  //   auth: {
  //     user: 'admin@webpetersen.com',
  //     pass: 'Ajcp0106%',
  //   },
  // });

  // //const emailHtml = render(<Email url="https://example.com" />);

  // const optionsSend = {
  //   from: 'a.eric.petersen@gmail.com',
  //   to: eMail,
  //   subject: subject,
  //   html: WelcomeEmail(fullName),
  // };

  // const optionsVisitor = {
  //   from: 'admin@webpetersen.com',
  //   to: 'a.eric.petersen@gmail.com',
  //   subject: subject,
  //   html: VisitorEmail(eMail, fullName, content),
  // };

  // transporter.sendMail(optionsSend);

  // transporter.sendMail(optionsVisitor);

  //   const data = {
  //     key: 'md-UZn_IVLThBLZIKFcVaKarw',
  //     message: {
  //       from_email: 'admin@webpetersen.com',
  //       to: [{ email:  eMail }],
  //       subject: subject,
  //       html: `<p>${fullName}</p><p>${content}</p>`,
  //     },
  //   };
  // console.log("DATA=", data);
  //   axios.post('https://mandrillapp.com/api/1.0/messages/send.json', data)
  //     .then(response => {
  //       console.log("mandrillClient result", response.data);
  //     })
  //     .catch(error => {
  //       console.error("mandrillClient error", error);
  //     });
  }






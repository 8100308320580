export function WelcomeEmail(fullName, subject) {
  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Adolfo Eric Petersen</title>
  </head>
  <body>
  <h4>Dear ${fullName},</h4>

  <p>Thank you for visiting my website and sending me a message regarding ${subject}. I appreciate the time you took to get in touch with me.</p>

  <p>I have received your message and will get back to you as soon as possible. In the meantime, feel free to explore my website for more information about my services and background.</p>
  
  <p>Thank you again for your interest in my work. I look forward to speaking with you soon.</p>
  
  <p>Best regards,</p>

  <table cellspacing="0" cellpadding="0" border="0" style="border-collapse:collapse; table-layout:fixed; overflow-wrap:break-word; word-wrap:break-word; word-break:break-word;" emb-background-style="" width="100%" direction="ltr">
    <tbody>
      <tr>
        <td>
          <table cellspacing="0" cellpadding="0" border="0" style="border-collapse:collapse; table-layout:fixed; overflow-wrap:break-word; word-wrap:break-word; word-break:break-word; direction:ltr;" emb-background-style="">
            <tbody>
              <tr>
                <td>
                  <table cellspacing="0" cellpadding="0" border="0" style="border-collapse:collapse; table-layout:fixed; overflow-wrap:break-word; word-wrap:break-word; word-break:break-word; direction:ltr;" width="100%" emb-background-style="">
                    <tbody>
                      <tr>
                        <td>
                          <p><b>Adolfo Eric Petersen</b></p>
                        </td>
                      </tr>
                      <tr>
                        <td style="color:#020203; text-decoration:none; font-size:14.3px; font-family:Poppins, Arial, Helvetica, sans-serif; font-weight:normal; line-height:18px; mso-line-height-rule:exactly; padding-bottom:5px;">Leader in Software Solution Delivery</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="tel:+33601234567" style="color:#020203; text-decoration:none; font-size:13px; font-family:Poppins, Arial, Helvetica, sans-serif; font-weight:normal; line-height:18px; mso-line-height-rule:exactly;">+49 1515 3499054</a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="mailto:a.eric.petersen@gmail.com" style="color:#020203; text-decoration:none; font-size:13px; font-family:Poppins, Arial, Helvetica, sans-serif; font-weight:normal; line-height:18px; mso-line-height-rule:exactly;">a.eric.petersen@gmail.com</a>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-top:15px;">
                          <span>
                            <a href="https://www.linkedin.com/in/adolfoericpetersen/" target="_blank" style="border:0; display:inline-block;">
                              <img width="20" height="20" src="https://external.traqpad.fr/brands/linkedin-original.png" style="border-radius:4px; display:block; border:none; width:20px; max-width:20px !important; height:20px; max-height:20px !important">
                            </a>&nbsp;&nbsp;
                          </span>
  
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  </body>
  </html>
  `;
  return html;
}

export function VisitorEmail(eMail, fullName, message) {
  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Adolfo Eric Petersen</title>
  </head>
  <body>
  <h4>New message from ${fullName} at ${eMail},</h4>

  <span>${message}</spanp>
  <br></br>
  <table cellspacing="0" cellpadding="0" border="0" style="border-collapse:collapse; table-layout:fixed; overflow-wrap:break-word; word-wrap:break-word; word-break:break-word;" emb-background-style="" width="100%" direction="ltr">
    <tbody>
      <tr>
        <td>
          <table cellspacing="0" cellpadding="0" border="0" style="border-collapse:collapse; table-layout:fixed; overflow-wrap:break-word; word-wrap:break-word; word-break:break-word; direction:ltr;" emb-background-style="">
            <tbody>
              <tr>
                <td>
                  <table cellspacing="0" cellpadding="0" border="0" style="border-collapse:collapse; table-layout:fixed; overflow-wrap:break-word; word-wrap:break-word; word-break:break-word; direction:ltr;" width="100%" emb-background-style="">
                    <tbody>
                      <tr>
                        <td>
                          <p><b>Adolfo Eric Petersen</b></p>
                        </td>
                      </tr>
                      <tr>
                        <td style="color:#020203; text-decoration:none; font-size:14.3px; font-family:Poppins, Arial, Helvetica, sans-serif; font-weight:normal; line-height:18px; mso-line-height-rule:exactly; padding-bottom:5px;">Leader in Software Solution Delivery</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="tel:+33601234567" style="color:#020203; text-decoration:none; font-size:13px; font-family:Poppins, Arial, Helvetica, sans-serif; font-weight:normal; line-height:18px; mso-line-height-rule:exactly;">+49 1515 3499054</a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="mailto:a.eric.petersen@gmail.com" style="color:#020203; text-decoration:none; font-size:13px; font-family:Poppins, Arial, Helvetica, sans-serif; font-weight:normal; line-height:18px; mso-line-height-rule:exactly;">a.eric.petersen@gmail.com</a>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-top:15px;">
                          <span>
                            <a href="https://www.linkedin.com/in/adolfoericpetersen/" target="_blank" style="border:0; display:inline-block;">
                              <img width="20" height="20" src="https://external.traqpad.fr/brands/linkedin-original.png" style="border-radius:4px; display:block; border:none; width:20px; max-width:20px !important; height:20px; max-height:20px !important">
                            </a>&nbsp;&nbsp;
                          </span>
  
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  </body>
  </html>
  `;
  return html;
}

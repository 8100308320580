export const languagesData = [
    {
        value: "en",
        country: "us",
        label: 'English',
        idiom: "english"
    },
    {
        value: "de",
        country: "de",
        label: 'Deutsch',
        idiom: "german"
    },
    {
        value: "es",
        country: "es",
        label: 'Español',
        idiom: "spanish"
    },
    {
        value: "pt",
        country: "br",
        label: 'Português',
        idiom: "portuguese"
    }
  ];
  
  export default languagesData;